import "./style.scss";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { FC, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { UPDATE_EMAIL_STEPS, UPDATE_PHONE_STEPS, UPDATE_PROFILE_WORKFLOW } from "./constants";
import { EmailVerification } from "./emailVerification";
import { EnterPhone } from "./enterPhone";
import { OTPVerification } from "./otpVerification";
import { PhoneVerification } from "./phoneVerification";
import { SSNVerification } from "./ssnVerification";
import { UpdateEmail } from "./updateEmail";
import { UpdatePhone } from "./updatePhone";

const UpdateProfile: FC = () => {
  const [step, setStep] = useState(0);
  const workflow = localStorage.getItem("profileUpdateWorkflow") as string;
  const history = useHistory();
  const worker = useDefinedWorker();

  const socialSecurityNumberExists = !!worker.socialSecurityNumber;

  useEffect(() => {
    if (!socialSecurityNumberExists) {
      setStep(1);
    }
  }, [socialSecurityNumberExists]);

  const onNext = () => {
    if (step === 3) {
      history.replace("/home/account");
    } else {
      setStep(step + 1);
    }
  };

  const pageTitle = useMemo(() => {
    if (workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_EMAIL) {
      return "Change Email";
    } else if (workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_PHONE) {
      return "Change Phone Number";
    }
    return null;
  }, [workflow]);

  return (
    <IonPage className="onboarding-page profile-update-container">
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/account" mode="ios" />
          </IonButtons>
          {pageTitle ? <IonTitle className="ion-text-center">{pageTitle}</IonTitle> : null}
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="profile-step-indicator">
          {socialSecurityNumberExists ? (
            <>
              <span className={step === 0 ? "active" : ""}>1</span>
              <i />
            </>
          ) : null}
          <span
            className={
              ([1, 2].includes(step) && workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_EMAIL) ||
              (step === 1 && workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_PHONE)
                ? "active"
                : ""
            }
          >
            {socialSecurityNumberExists ? 2 : 1}
          </span>
          <i />
          <span
            className={
              (step === 3 && workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_EMAIL) ||
              ([2, 3].includes(step) && workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_PHONE)
                ? "active"
                : ""
            }
          >
            {socialSecurityNumberExists ? 3 : 2}
          </span>
        </div>
        {/* Update email workflow */}
        {workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_EMAIL &&
          step === (UPDATE_EMAIL_STEPS.SSN_VERIFICATION as number) && (
            <SSNVerification stepFinished={onNext} />
          )}
        {workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_EMAIL &&
          step === (UPDATE_EMAIL_STEPS.PHONE_VERIFICATION as number) && (
            <PhoneVerification stepFinished={onNext} />
          )}
        {workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_EMAIL &&
          step === (UPDATE_EMAIL_STEPS.OTP_VERIFICATION as number) && (
            /**
             * FIXME - remove this IonContent once we refactor to `PageWithHeader`
             */
            <IonContent>
              <OTPVerification stepFinished={onNext} />
            </IonContent>
          )}
        {workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_EMAIL &&
          step === (UPDATE_EMAIL_STEPS.UPDATE_EMAIL as number) && (
            <UpdateEmail stepFinished={onNext} />
          )}

        {/* Update phone workflow */}
        {workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_PHONE &&
          step === (UPDATE_PHONE_STEPS.SSN_VERIFICATION as number) && (
            <SSNVerification stepFinished={onNext} />
          )}
        {workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_PHONE &&
          step === (UPDATE_PHONE_STEPS.EMAIL_VERIFICATION as number) && (
            <EmailVerification stepFinished={onNext} />
          )}
        {workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_PHONE &&
          step === (UPDATE_PHONE_STEPS.ENTER_PHONE as number) && (
            <EnterPhone stepFinished={onNext} />
          )}
        {workflow === UPDATE_PROFILE_WORKFLOW.UPDATE_PHONE &&
          step === (UPDATE_PHONE_STEPS.UPDATE_PHONE as number) && (
            <UpdatePhone stepFinished={onNext} />
          )}
      </IonContent>
    </IonPage>
  );
};

export { UpdateProfile };
